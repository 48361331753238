<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-form ref="form" @submit.prevent="null">
			<v-container class="d-flex flex-column align-center">
				<v-row style="width: 100%" dense>
					<v-col cols="12">
						<TextField
							:disabled="inputDisable"
							v-model="flightNumber"
							:placeholder="t('form.placeholder')"
							prepend-inner-icon="airplane_ticket"
							:title="`${t('form.flightNumber')}*`"
						/>
					</v-col>
					<v-col cols="6">
						<DateField
							:menu-disabled="inputDisable"
							:disabled="inputDisable"
							:reference-date="checkinModel.flightDatetime"
							:disabledPrevius="1"
							:disabledNext="1"
							v-model="flightDatetime"
							:title="
								isArrival
									? t('form.arrivalDate')
									: t('form.takeoffDate')
							"
						/>
					</v-col>
					<v-col cols="6">
						<TimeField
							:disabled="inputDisable"
							:menu-disabled="inputDisable"
							v-model="flightDatetime"
							:title="
								isArrival
									? t('form.arrivalTime')
									: t('form.takeoffTime')
							"
						/>
					</v-col>
				</v-row>

				<v-btn
					rounded
					class="mt-4"
					color="primary"
					block
					:loading="loading"
					:disabled="!canSearch || inputDisable"
					@click="flightValidate"
					>{{ t("btnSearch") }}</v-btn
				>
			</v-container>
		</v-form>

		<CardFlightInfo v-if="flightInfo" :flightInfo="flightInfo" />

		<v-container class="mb-3">
			<!-- Este boton se usaba para permitir vuelos invalidos -->
			<!-- <v-btn
        v-if="!canSearch && !isValid"
        rounded
        class="mb-5 caption font-weight-black"
        color="primary"
        block
        @click="save()"
        >{{ t("form.btnSaveInvalid") }}</v-btn
      > -->
			<LayoutBtn
				:edit="$t('global.btnCancel')"
				:confirm="$t('global.btnSave')"
				:disabledConfirm="disabledSave"
				@click-confirm="save()"
				@click-edit="cancel()"
			/>
		</v-container>
	</div>
</template>

<script>
import TextField from "@/components/field/TextField";
import { mapFields } from "@/helpers/mapFields";
import { api } from "@/service/api.service";
import LayoutBtn from "@/layouts/LayoutBtn";
import DateField from "@/components/field/DateField";
import TimeField from "@/components/field/TimeField";
import CardFlightInfo from "@/components/card/CardFlightInfo";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { FORMAT_DATE_PICKER } from "@/helpers/constants";
import dayjs from "dayjs";
export default {
	components: {
		DateField,
		TextField,
		TimeField,
		CardFlightInfo,
		LayoutBtn
	},
	data: () => ({
		flightInfo: null,
		loading: false,
		isValid: true,
		canSearch: false,
		canSave: false
	}),
	watch: {
		flightNumber() {
			this.isValid = true;
			this.canSearch = true;
		},
		flightDatetime() {
			this.isValid = true;
			this.canSearch = true;
		}
	},
	mounted() {
		if (this.$refs.form.validate()) this.canSearch = true;
	},

	computed: {
		...mapGetters("service", [
			"isArrival",
			"getDirection",
			"getBasePath",
			"inputDisable"
		]),
		...mapState("service", ["checkinModel", "auxModel"]),
		...mapFields({
			fields: [
				"flightNumber",
				"flightDatetime",
				"flightValidationStatus",
				"flightValidationData"
			],
			base: "auxModel",
			mutation: "SET_UPDATE_AUXMODEL",
			module: "service"
		}),
		disabledSave() {
			if (this.canSearch) if (this.canSave) return false;
			return true;
		},
		airportIata: vm =>
			vm.isArrival
				? vm.checkinModel.pickup.iata
				: vm.checkinModel.dropoff.iata
	},
	methods: {
		...mapMutations("service", ["SET_AUXMODEL", "SET_MODEL"]),
		...mapActions("service", ["_calcPickupDateTime"]),
		...mapMutations("service", ["SET_REFERENCE", "SET_RECOMMENDED"]),
		t(key, options = {}) {
			return this.$t(`section.flight.${key}`, options);
		},
		async save() {
			if (!this.$refs.form.validate()) return;
			this.SET_REFERENCE(null);
			this.SET_RECOMMENDED(false);
			await this._calcPickupDateTime();
			this.SET_MODEL(this.auxModel);
			this.back();
		},
		cancel() {
			this.SET_AUXMODEL(this.checkinModel);
			this.back();
		},
		back() {
			this.$router.replace(this.getBasePath(this.$route.meta.back));
		},
		async flightValidate() {
			if (!this.$refs.form.validate()) return null;
			this.flightValidationStatus = "none";
			this.flightValidationData = {};

			this.flightInfo = null;

			this.canSave = false;
			this.canSearch = false;

			this.loading = true;
			const params = {
				flightNumber: this.flightNumber,
				flightDate: dayjs(this.flightDatetime).format(
					FORMAT_DATE_PICKER
				),
				airportIata: this.airportIata,
				direction: this.getDirection
			};
			try {
				const response = await api.get("/travelers/flights/validate", {
					params
				});
				this.isValid = response?.data?.flightInfo ? true : false;
				if (this.isValid) {
					this.canSave = true;
					this.canSearch = true;

					this.flightInfo = response.data.flightInfo;
					this.flightValidationStatus = "api";
					this.flightValidationData = this.flightInfo;
					this.flightDatetime = this.isArrival
						? this.flightInfo.arrival.scheduledTime
						: this.flightInfo.departure.scheduledTime;
				} else {
          this.$root.show({
						type: "error",
						title: this.t("invalid.title"),
						description: this.t("invalid.description"),
            contact: true
					});
        }
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		}
	}
};
</script>
