<template>
  <div class="fill-height d-flex flex-column justify-space-between">
    <div class="d-flex flex-column justify-start align-center">
      <v-toolbar extended color="primary" style="width: 100%" flat v-if="listFlight.length > 0" max-width="720px">
        <v-toolbar dense class="rounded-lg mt-3" color="white">
          <v-container
            class="mx-n1 d-flex flex-row justify-space-between mx-0 px-0"
          >
            <div class="body-2 header-location">
              <v-icon class="mb-1">flight_land</v-icon>
              {{ checkinModel.pickup.fullName }}
            </div>
            <div class="body-2 header-date mx-1">
              <v-icon class="mb-1 ">event</v-icon>{{ dateSelected }}
            </div>
            <div class="body-2 header-time primary--text" @click="reset()">
              <v-icon class="mb-1 " color="primary">schedule</v-icon
              >{{ timeSelectedText }}
            </div>
          </v-container>
        </v-toolbar>

        <template v-slot:extension>
          <v-container
            class="d-flex flex-row justify-space-between body-2 white--text mx-0 px-0"
          >
            <FilterMenu v-model="filterAirport" :items="listFilterAirport">
              Filter by airport</FilterMenu
            >
            <FilterMenu v-model="filterAirline" :items="listFilterAirline"
              >Filter by airlane</FilterMenu
            >
          </v-container>
        </template>
      </v-toolbar>
      <v-container
        class="d-flex flex-column align-center"
        v-if="listFlight.length === 0"
      >
        <v-form ref="form">
          <v-row style="width: 100%" dense>
            <v-col cols="12">
              <TextField
                disabled
                :value="
                  isArrival
                    ? checkinModel.pickup.fullName
                    : checkinModel.dropoff.fullName
                "
                :title="`${fieldTitle}*`"
              />
            </v-col>
            <v-col cols="6">
              <DateField
                :menu-disabled="inputDisable"
                :disabled="inputDisable"
                :reference-date="checkinModel.flightDatetime"
                v-model="flightDatetime"
                :title="dateTitle"
                :disabledPrevius="1"
                :disabledNext="1"
              />
            </v-col>
            <v-col cols="6">
              <TimeField
                :disabled="inputDisable"
                :menu-disabled="inputDisable"
                v-model="flightDatetime"
                :title="selectTitle"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-btn
          rounded
          class="mt-4"
          color="primary"
          block
          :disabled="!canSearch || inputDisable"
          :loading="loading"
          @click="searchFlight()"
          >{{ t("btnSearch") }}</v-btn
        >
      </v-container>
      <ErrorSearch v-if="!isValid" />
    </div>
    <CardFlightInfo v-if="flightInfo" :flightInfo="flightInfo" />
    <v-container v-else>
      <ListFlight
        v-show="isValid"
        @selected="selected"
        :items="listFlight"
        :filter-airport="filterAirport"
        :filter-airline="filterAirline"
      />
    </v-container>
    <v-container class="mb-3" v-show="listFlight.length === 0">
      <LayoutBtn
        :edit="$t('global.btnCancel')"
        :confirm="$t('global.btnSave')"
        :disabled-confirm="disabledSave"
        @click-confirm="save()"
        @click-edit="cancel()"
      />
    </v-container>
  </div>
</template>

<script>
import TextField from "@/components/field/TextField";
import DateField from "@/components/field/DateField";
import TimeField from "@/components/field/TimeField";
import CardFlightInfo from "@/components/card/CardFlightInfo";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { mapFields } from "@/helpers/mapFields";
import { api } from "@/service/api.service";
import { LIST_HOURS } from "@/helpers/utils";
import FilterMenu from "./FilterMenu";
import ListFlight from "./ListFlight";
import ErrorSearch from "./ErrorSearch";
import LayoutBtn from "@/layouts/LayoutBtn";
import {
  FORMAT_TIME_EMPTY,
  FORMAT_DATE,
  FORMAT_TIME_A
} from "@/helpers/constants";
import dayjs from "dayjs";
export default {
  props: {
    value: Boolean
  },
  components: {
    CardFlightInfo,
    ListFlight,
    FilterMenu,
    TimeField,
    LayoutBtn,
    TextField,
    ErrorSearch,
    DateField
  },
  data: () => ({
    hours: LIST_HOURS(),
    timeSelected: null,
    timePlaceholder: FORMAT_TIME_EMPTY,
    loading: false,
    listFlight: [],
    filterAirline: null,
    filterAirport: null,
    flightInfo: null,

    canSearch: false,
    canSave: false,
    isValid: true
  }),
  watch: {
    value(v) {
      if (!v) this.reset();
    },
    flightDatetime() {
      this.isValid = true;
      this.canSearch = true;
    }
  },

  mounted() {
    if (this.$refs.form.validate()) this.canSearch = true;
  },

  computed: {
    ...mapGetters("service", [
      "isArrival",
      "getDirection",
      "getBasePath",
      "inputDisable"
    ]),
    ...mapState("service", ["checkinModel", "auxModel"]),
    ...mapFields({
      fields: [
        "flightNumber",
        "flightDatetime",
        "flightValidationStatus",
        "flightValidationData"
      ],
      base: "auxModel",
      mutation: "SET_UPDATE_AUXMODEL",
      module: "service"
    }),
    disabledSave() {
      if (this.canSearch) if (this.canSave) if (this.flightInfo) return false;
      return true;
    },
    listFilterAirport: vm => {
      if (vm.listFlight.length === 0) return [];
      if (vm.isArrival)
        return [
          ...new Set(vm.listFlight.map(item => item.departure.airportName))
        ];
      if (!vm.isArrival)
        return [
          ...new Set(vm.listFlight.map(item => item.arrival.airportName))
        ];
    },
    listFilterAirline: vm => {
      if (vm.listFlight.length === 0) return [];
      return [...new Set(vm.listFlight.map(item => item.airline))];
    },
    dateTitle: vm =>
      vm.isArrival ? vm.t("form.arrivalDate") : vm.t("form.takeoffDate"),
    fieldTitle: vm =>
      vm.isArrival
        ? vm.t("form.arrivalAirport")
        : vm.t("form.departureAirport"),
    selectTitle: vm =>
      vm.isArrival ? vm.t("form.arrivalTime") : vm.t("form.takeoffTime"),
    dateSelected: vm => dayjs(vm.flightDatetime).format(FORMAT_DATE),
    timeSelectedText: vm => dayjs(vm.flightDatetime).format(FORMAT_TIME_A)
  },
  methods: {
    ...mapMutations("service", ["SET_AUXMODEL", "SET_MODEL"]),
    ...mapActions("service", ["_calcPickupDateTime"]),
    ...mapMutations("service", ["SET_REFERENCE", "SET_RECOMMENDED"]),
    t(key, options = {}) {
      return this.$t(`section.flight.${key}`, options);
    },
    reset() {
      this.listFlight = [];
      this.$emit("input", false);
      this.filterAirline = null;
      this.filterAirport = null;
    },
    selected(val) {
      this.flightInfo = null;
      this.flightInfo = val;
      this.listFlight = [];
      this.$emit("input", false);

      this.flightValidationStatus = "api";
      this.flightValidationData = this.flightInfo;
      this.flightNumber = this.flightInfo.number;
      this.flightDatetime = this.isArrival
        ? this.flightInfo.arrival.scheduledTime
        : this.flightInfo.departure.scheduledTime;
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      this.SET_REFERENCE(null);
      this.SET_RECOMMENDED(false);
      await this._calcPickupDateTime();
      this.SET_MODEL(this.auxModel);
      this.back();
    },
    cancel() {
      this.SET_AUXMODEL(this.checkinModel);
      this.back();
    },
    back() {
      this.$router.replace(this.getBasePath(this.$route.meta.back));
    },
    async searchFlight() {
      if (!this.$refs.form.validate()) return;
      this.flightInfo = null;

      this.listFlight = [];
      this.canSave = false;
      this.canSearch = false;

      const airportRef = this.isArrival
        ? this.checkinModel.pickup.type + "-" + this.checkinModel.pickup.id
        : this.checkinModel.dropoff.type + "-" + this.checkinModel.dropoff.id;
      const params = {
        airportRef: airportRef,
        serviceDatetime: this.flightDatetime,
        direction: this.getDirection
      };
      this.loading = true;
      try {
        const response = await api.get("/travelers/flights/search", {
          params
        });
        if (response.data.length > 0) {
          this.isValid = true;
          this.canSave = true;
          this.canSearch = true;
          this.listFlight = response.data;
          this.$emit("input", true);
        } else {
          this.isValid = false;
        }
      } catch (error) {
        this.$emit("input", false);
        this.isValid = false;
        console.log(error);
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin config($width) {
  width: $width;
  white-space: nowrap;
  text-overflow: "";
  overflow: hidden;
}

.header-location {
  @include config(25%);
}
.header-date {
  @include config(40%);
}
.header-time {
  @include config(30%);
}
</style>
