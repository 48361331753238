<template>
	<div class="fill-height d-flex flex-column">
		<v-tabs fixed-tabs v-model="tab">
			<v-tabs-slider ref="tabslider"></v-tabs-slider>
			<v-tab
				class="rounded-t-lg text-capitalize font-weight-bold"
				@click="resetClass"
			>
				{{ t("tab.byNumber") }}
			</v-tab>
			<v-tab
				:style="scheduleFilter ? 'background-color: #4F39CF' : ''"
				:class="
					`rounded-t-lg text-capitalize font-weight-bold ${
						scheduleFilter ? 'white--text' : ''
					}`
				"
			>
				{{ t("tab.bySchedule") }}
			</v-tab>
		</v-tabs>
		<div class="fill-height d-flex flex-column justify-space-between">
			<v-tabs-items class="fill-height " v-model="tab">
				<v-tab-item class="fill-height">
					<TabNumber />
				</v-tab-item>
				<v-tab-item class="fill-height">
					<TabSchedule v-model="scheduleFilter" />
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<script>
import TabSchedule from "./TabSchedule";
import TabNumber from "./TabNumber";
export default {
	components: {
		TabSchedule,
		TabNumber
	},

	data: () => ({
		tab: null,
		scheduleFilter: false
	}),
	watch: {
		scheduleFilter(v) {
			if (v) {
				this.darkClass();
			} else {
				this.resetClass();
			}
		}
	},
	methods: {
		t(key, options = {}) {
			return this.$t(`section.flight.${key}`, options);
		},
		resetClass() {
			let slider = this.$refs.tabslider.$el;
			slider.classList.remove("tab-slider-filter");
			slider.parentNode.classList.remove("parent-slider-filter");
			this.scheduleFilter = false;
		},
		darkClass() {
			let slider = this.$refs.tabslider.$el;
			slider.classList.add("tab-slider-filter");
			slider.parentNode.classList.add("parent-slider-filter");
		}
	}
};
</script>
<style lang="scss" scoped>
.v-tabs:not(.v-tabs--vertical) .v-tab {
	white-space: normal;
	border-bottom: 1px solid #4f39cf;
	&.v-tab--active {
		color: inherit;
		border-top: 1px solid #4f39cf;
		border-right: 1px solid #4f39cf;
		border-left: 1px solid #4f39cf;
		border-bottom: 1px solid #ffffff;
	}
}
@mixin slider($color) {
	background-color: $color;
	position: absolute;
	left: 10%;
	right: 10%;
	height: 100%;
	width: 80%;
}
.v-tabs-slider {
	@include slider(#4f39cf);
}
::v-deep .tab-slider-filter {
	@include slider(white);
}

::v-deep .parent-slider-filter {
	bottom: 0;
	margin: 0 !important;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	z-index: 1;
	background-color: #4f39cf;
}
</style>
