<template>
	<div>
		<slot />
		<v-menu offset-y left allow-overflow max-height="220px">
			<template v-slot:activator="{ on, attrs }">
				<v-icon color="white" v-bind="attrs" v-on="on"
					>arrow_drop_down</v-icon
				>
			</template>
			<v-list dense>
				<v-list-item-group
					active-class="primary white--text"
					v-model="model"
				>
					<v-list-item
						:value="item"
						v-for="(item, i) in items"
						:key="i"
					>
						<v-list-item-title>{{ item }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	props: {
		value: String,
		items: Array
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(v) {
				this.$emit("input", v);
			}
		}
	}
};
</script>
