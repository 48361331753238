<template>
	<v-container class="d-flex flex-column align-center">
		<v-icon color="secondary">error_outline</v-icon>
		<v-card-text
			v-html="t('notresult')"
			class="flex text-center font-weight-thin"
		>
		</v-card-text>
	</v-container>
</template>

<script>
export default {
	methods: {
		t(key, options = {}) {
			return this.$t(`section.flight.${key}`, options);
		}
	}
};
</script>
