<template>
	<div>
		<div
			v-for="(item, i) in itemsFiltered"
			:key="i"
			@click="$emit('selected', item)"
		>
			<div>
				<div class="title font-weight-black">
					{{ item.number }} {{ item.airline }}
				</div>
				<div class="body-2 d-flex flex-row justify-space-between">
					<div class="card-location-departure ">
						{{ item.departure.airportName }}
					</div>
					<div class="card-location-arrival">
						{{ item.arrival.airportName }}
					</div>
				</div>
				<div class="body-2 d-flex flex-row justify-space-between">
					<div class="primary--text font-weight-medium">
						{{ timeFormat(item.departure.scheduledTime) }}
					</div>
					<div class="primary--text font-weight-medium">
						{{ timeFormat(item.arrival.scheduledTime) }}
					</div>
				</div>
				<div class="body-2 d-flex flex-row justify-space-between">
					<div>{{ dayFormat(item.departure.scheduledTime) }}</div>
					<div>{{ dayFormat(item.arrival.scheduledTime) }}</div>
				</div>
				<div class="body-2 d-flex flex-row justify-space-between">
					<div>{{ dateFormat(item.departure.scheduledTime) }}</div>
					<div><v-icon color="secondary">arrow_forward</v-icon></div>
					<div>{{ dateFormat(item.arrival.scheduledTime) }}</div>
				</div>
			</div>
			<v-divider class="my-2"></v-divider>
		</div>
		<ErrorSearch v-if="itemsFiltered.length === 0" />
	</div>
</template>

<script>
import dayjs from "dayjs";
import { FORMAT_DATE, FORMAT_TIME_A, FORMAT_DAY } from "@/helpers/constants";
import ErrorSearch from "./ErrorSearch";
import { mapGetters } from "vuex";
export default {
	components: {
		ErrorSearch
	},
	props: {
		items: Array,
		filterAirport: String,
		filterAirline: String
	},
	computed: {
		...mapGetters("service", ["isArrival"]),
		itemsFiltered: vm => {
			if (!vm.filterAirport && !vm.filterAirline) return vm.items;
			let filtered = [];
			if (vm.isArrival) {
				if (vm.filterAirport) {
					filtered = vm.items.filter(item => {
						return item.departure.airportName === vm.filterAirport;
					});
				} else {
					filtered = vm.items;
				}
			}
			if (!vm.isArrival)
				if (vm.filterAirport) {
					filtered = vm.items.filter(item => {
						item.arrival.airportName === vm.filterAirport;
					});
				} else {
					filtered = vm.items;
				}
			if (vm.filterAirline)
				return filtered.filter(item => {
					return item.airline === vm.filterAirline;
				});
			return filtered;
		}
	},
	methods: {
		dateFormat(date) {
			if (date === "") return;
			return dayjs(date).format(FORMAT_DATE);
		},
		timeFormat(date) {
			if (date === "") return;
			return dayjs(date).format(FORMAT_TIME_A);
		},
		dayFormat(date) {
			if (date === "") return;
			return dayjs(date).format(FORMAT_DAY);
		}
	}
};
</script>
<style lang="scss" scoped>
.card-location-arrival {
	width: 45%;
	text-align: end;
}
.card-location-departure {
	width: 45%;
	text-align: start;
}
</style>
