<template>
	<v-container class="py-0 px-1  d-flex justify-center">
		<div class="ticket-container">
			<v-img
				max-width="500px"
				height="auto"
				src="~@/assets/ticket.svg"
			></v-img>
			<div class="ticket-content d-flex flex-column justify-center">
				<div
					class="ml-1  text-start title font-weight-black ticket-title "
				>
					{{ flightInfo.number }} {{ flightInfo.airline }}
				</div>

				<table class="body-2 font-weight-thin mt-2">
					<tr>
						<td>
							<v-icon
								color="secondary"
								class="material-icons-outlined"
								>flight_takeoff</v-icon
							>
						</td>
						<td>
							{{ flightInfo.departure.airportName }}
						</td>
						<td>
							<div>
								<div class="font-weight-bold">
									{{
										parseTime(
											flightInfo.departure.scheduledTime
										)
									}}
								</div>
								<div>
									{{
										parseDay(
											flightInfo.departure.scheduledTime
										)
									}}
								</div>
								<div>
									{{
										parseDate(
											flightInfo.departure.scheduledTime
										)
									}}
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<v-icon
								color="secondary"
								class="material-icons-outlined"
								>flight_land</v-icon
							>
						</td>
						<td>
							{{ flightInfo.arrival.airportName }}
						</td>
						<td>
							<div>
								<div class="font-weight-bold">
									{{
										parseTime(
											flightInfo.arrival.scheduledTime
										)
									}}
								</div>
								<div>
									{{
										parseDay(
											flightInfo.arrival.scheduledTime
										)
									}}
								</div>
								<div>
									{{
										parseDate(
											flightInfo.arrival.scheduledTime
										)
									}}
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</v-container>
</template>

<script>
import dayjs from "dayjs";
import { FORMAT_TIME_A, FORMAT_DAY, FORMAT_DATE } from "@/helpers/constants";
export default {
	props: {
		flightInfo: Object
	},
	methods: {
		parseTime(date) {
			return dayjs(date).format(FORMAT_TIME_A);
		},
		parseDay(date) {
			return dayjs(date).format(FORMAT_DAY);
		},
		parseDate(date) {
			return dayjs(date).format(FORMAT_DATE);
		}
	}
};
</script>

<style lang="scss" scoped>
.ticket-container {
  width: 99%;
	max-width: 500px;
	position: relative;
	display: inline-block;
	text-align: center;
}
.ticket-title {
	position: absolute;
	top: -0.2rem;
}
.ticket-content {
	position: absolute;
	height: 100%;
	width: 95%;
	top: 10px;
	left: 10px;
}
table {
	max-width: 465px;
	width: 93%;
}

td {
	vertical-align: top;
	text-align: left;
	padding: 0px 2px 4px 2px;
}
td div div {
	margin-bottom: -1px;
}
</style>
